import axios from "axios";
// states
const state = () => ({
});

// getters
const getters = {
};

// actions
const actions = {
  getJurisdictions() {
    return new Promise((resolve, reject) => {
      axios
        .get("/jurisdictions")
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  getTalents() {
    return new Promise((resolve, reject) => {
      axios
        .get("/talents")
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  getShrines() {
    return new Promise((resolve, reject) => {
      axios
        .get("/shrines")
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  getParishes() {
    return new Promise((resolve, reject) => {
      axios
        .get("/parishes")
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  getTrainings() {
    return new Promise((resolve, reject) => {
      axios
        .get("/trainings")
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  getAllCatechist(_, page) {
    return new Promise((resolve, reject) => {
      axios
        .get("/catechists?page=" + page + "&limit=12")
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  downloadCatechistFile(_, data) {
    return new Promise((resolve, reject) => {
      axios
        .get("reports/catechist/" + data.idCard + "/pdf", {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/pdf",
          },
          responseType: "blob",
        })
        .then(function (response) {
          console.log(response);
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", data.name + ".pdf"); //or any other extension
          document.body.appendChild(link);
          link.click();
          resolve();
        })
        .catch(function (error) {
          reject(error);
        });
    });
  },
  searchCatechists(_, data) {
    let query = "parameter=" + data.name + "&page=" + data.page;
    if (data.jurisdiction) query = query + "&jurisdiction=" + data.jurisdiction;
    if (data.talent) query = query + "&talent=" + data.talent;
    if (data.shrine) query = query + "&shrine=" + data.shrine;
    query = query + "&limit=12" ;
    return new Promise((resolve, reject) => {
      axios
        .get("/catechists/search?" + query)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  getCatechistInfo(_, data) {
    return new Promise((resolve, reject) => {
      axios
        .get("/catechists/" + data)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  registerCatechist(_, data) {
    return new Promise((resolve, reject) => {
      axios
        .post("/catechists", data)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  updateCatechists(_, data) {
    return new Promise((resolve, reject) => {
      axios
        .put("/catechists", data)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => reject(error));
    });
  },
  validateCatechistCode(_, data) {
    return new Promise((resolve, reject) => {
      axios
        .get("/catechists/validate?qrCode=" + data)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => reject(error));
    });
  },
  findCatechistSuggests({ getters }, data) {
    return new Promise((resolve, reject) => {
      if (!getters.isLoggedIn) {
        resolve();
      }
      axios
        .get("/catechists/suggests?param=" + data)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => reject(error));
    });
  },
  getCatechistReportAnonymous(_, data) {
    return new Promise((resolve, reject) => {
      axios
        .get(
          "reports/catechists/pdf?id=" +
            data.idNumber +
            "&password=" +
            data.password,
          {
            headers: {
              "Content-Type": "application/json",
              Accept: "application/pdf",
            },
            responseType: "blob",
          }
        )
        .then(function (response) {
          console.log(response);
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", data.idNumber + ".pdf");
          document.body.appendChild(link);
          link.click();
          resolve();
        })
        .catch(function (error) {
          reject(error);
        });
    });
  },
  getCatechistTemplate() {
    return new Promise((resolve, reject) => {
      axios
        .get("reports/template", {
          headers: {
            "Content-Type": "application/json",
//            Accept: "application/pdf",
          },
          responseType: "blob",
        })
        .then(function (response) {
          console.log(response);
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "catequistas.xlsx");
          document.body.appendChild(link);
          link.click();
          resolve();
        })
        .catch(function (error) {
          reject(error);
        });
    });
  },
};

// mutations
const mutations = {};

export default {
  state,
  getters,
  actions,
  mutations,
};
