<template>
  <v-app>
    <v-app-bar
      app
      elevate-on-scroll
      color="secondary"
      src="@/assets/banner.jpg"
      dark
      extended
    >
      <template v-slot:img="{ props }">
        <v-img
          v-bind="props"
          gradient="to top right, rgba(255,255,255,.7), rgba(159, 172, 214,.9)"
        ></v-img>
      </template>
      <template v-slot:extension>
        <v-spacer />
        <v-card-title class="justify-center">
          <div>
            <v-btn
              v-for="(link, i) in links"
              :key="i"
              color="primary"
              rounded
              class="my-2 text-caption"
              :to="link.to"
            >
              {{ link.link }}
            </v-btn>
          </div>
          <div v-if="$store.getters.isLoggedIn">
            <v-btn
              v-for="(linksAu, i) in linksAuth"
              :key="linksAu.to + i"
              color="primary"
              rounded
              class="my-2 text-caption"
              :to="linksAu.to"
            >
              {{ linksAu.link }}
            </v-btn>
          </div>
          <v-btn
            v-if="$store.getters.isLoggedIn && $store.getters.isSudo"
            color="primary"
            to="/admins"
            rounded
            class="my-2 text-caption"
          >
            Administracion
          </v-btn>
        </v-card-title>
        <v-spacer />
      </template>
      <div class="d-flex align-center">
        <router-link to="/">
          <v-img
            alt="Vuetify Logo"
            class="shrink mr-2"
            contain
            src="./assets/logo.png"
            transition="scale-transition"
            width="40"
          />
        </router-link>

        <!-- <v-img
          alt="Vuetify Name"
          class="shrink mt-1 hidden-sm-and-down"
          contain
          min-width="100"
          src="https://cdn.vuetifyjs.com/images/logos/vuetify-name-light.png"
          width="100"
        /> -->
        <router-link to="/" style="text-decoration: none; color: inherit">
          <v-card-title
            class="primary--text"
            :class="{
              'subtitle-2': $vuetify.breakpoint.smAndDown,
              'display-0': $vuetify.breakpoint.mdAndUp,
            }"
          >
            Iglesia que peregrina en Bolivia
          </v-card-title>
        </router-link>
      </div>

      <v-spacer></v-spacer>

      <v-btn
        v-if="!$store.getters.isLoggedIn"
        @click.stop="showAuthDialog = true"
        fab
      >
        <v-icon>mdi-account-multiple</v-icon>
      </v-btn>

      <account-avatar-component v-if="$store.getters.isLoggedIn" />
    </v-app-bar>

    <v-main>
      <div class="bg">
        <v-slide-y-transition mode="out-in">
          <router-view />
        </v-slide-y-transition>
        <br />
      </div>

      <LoadingDialog />
      <NotValidDialog />
      <ChangePasswordDialog />
      <DownloadDialog />
      <SuccessDialog />
      <AuthDialog :visible="showAuthDialog" @close="showAuthDialog = false" />
      <WrongFile />
    </v-main>
    <the-footer />
  </v-app>
</template>

<script>
import AccountAvatarComponent from "@/components/AccountAvatarComponent.vue";
import AuthDialog from "@/components/dialogs/AuthDialog.vue";
import LoadingDialog from "@/components/dialogs/LoadingDialog.vue";
import TheFooter from "@/components/TheFooter.vue";
import NotValidDialog from "@/components/dialogs/NotValidDialog.vue";
import ChangePasswordDialog from "@/components/dialogs/ChangePasswordDialog.vue";
import DownloadDialog from "@/components/dialogs/DownloadDialog.vue";
import SuccessDialog from "@/components/dialogs/SuccessDialog.vue";
import WrongFile from "./components/dialogs/WrongFile.vue";

export default {
  components: {
    AuthDialog,
    AccountAvatarComponent,
    TheFooter,
    LoadingDialog,
    NotValidDialog,
    ChangePasswordDialog,
    DownloadDialog,
    SuccessDialog,
    WrongFile,
  },
  data: () => ({
    showAuthDialog: false,
    links: [
      { link: "Inicio", to: "/" },
      { link: "Verificar", to: "/scan" },
      { link: "Sobre nosotros", to: "/about" },
    ],
    linksAuth: [
      { link: "Registrar", to: "/register" },
      { link: "Cuenta", to: "/account" },
      { link: "Tablero", to: "/dashboard" },
    ],
  }),
};
</script>
<style scoped>
.bg {
  height: 100%;
  background-image: url("./assets/logo_transparent.png") !important;
  background-position: center;
  background-repeat: no-repeat;
  background-color: rgba(255, 255, 255, 0.5);
}
</style>
