// states
const state = () => ({
  validationD: false,
  loadingD: false,
  updatingPasswordD: false,
  downloadD: false,
  successD: false,
  unsupportedD: false,
});

// getters
const getters = {
  isValidationD(state) {
    return state.validationD;
  },
  isLoadingD(state) {
    return state.loadingD;
  },
  isUpdatingPasswordD(state) {
    return state.updatingPasswordD;
  },
  isDownloading(state) {
    return state.downloadD;
  },
  isSuccessDialog(state) {
    return state.successD;
  },
  isErrorFileDialog(state) {
    return state.unsupportedD;
  },
};

// actions
const actions = {
  changeValidationStatus({ commit }, data) {
    commit("CHANGE_STATUS_VALIDATION", data);
  },
  changeLoadingStatus({ commit }, data) {
    commit("CHANGE_STATUS_LOADING", data);
  },
  changeUpdatingPassStatus({ commit }, data) {
    commit("CHANGE_STATUS_UPDATING_PASSWORD", data);
  },
  changeDownloadData({ commit }, data) {
    commit("CHANGE_DOWNLOAD_DATA", data);
  },
  changeSuccessDialog({ commit }, data) {
    commit("CHANGE_SUCCESS_DATA", data);
  },
  fileUnsupportedDialog({ commit }, data) {
    commit("CHANGE_UNSUPPORTED_FILE", data);
  },
};

// mutations
const mutations = {
  CHANGE_STATUS_VALIDATION(state, payload) {
    state.validationD = payload;
  },
  CHANGE_STATUS_LOADING(state, payload) {
    state.loadingD = payload;
  },
  CHANGE_STATUS_UPDATING_PASSWORD(state, payload) {
    state.updatingPasswordD = payload;
  },
  CHANGE_DOWNLOAD_DATA(state, payload) {
    state.downloadD = payload;
  },
  CHANGE_SUCCESS_DATA(state, payload) {
    state.successD = payload;
  },
  CHANGE_UNSUPPORTED_FILE(state, payload) {
    state.unsupportedD = payload;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
