<template>
  <div class="text-center">
    <v-dialog v-model="dialog" persistent width="300">
      <v-card color="primary" dark>
        <v-card-text class="text-center">
          Procesando información
          <v-progress-linear
            indeterminate
            color="white"
            class="mb-0"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  computed: {
    dialog: {
      get() {
        return this.$store.getters.isLoadingD;
      },
      set(value) {
        this.$store.commit("changeLoadingStatus", value);
      },
    },
  },
};
</script>

<style>
</style>