import axios from "axios";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import Vue from "vue";
import VueQrcodeReader from "vue-qrcode-reader";
import "@/plugins/echarts";

Vue.use(VueQrcodeReader);
Vue.config.productionTip = false;
if (process.env.NODE_ENV === "production") {
  console.log("prod");
  axios.defaults.baseURL = "https://ceb-backend.herokuapp.com/";
} else {
  console.log("dev");
  axios.defaults.baseURL = "http://localhost:9000/";
}

if (localStorage.getItem("access_token")) {
  axios.defaults.headers.common.Authorization =
    "Bearer " + localStorage.getItem("access_token");
}

axios.interceptors.response.use(
  (response) => response,
  (error) => {
    console.warn("Interceptor running");
    const expirationTime = parseInt(localStorage.getItem("expires_in"));
    if (expirationTime) {
      console.log(error.config);
      if (error.response.status !== 401) {
        return Promise.reject(error);
      }
      const actualTime = new Date().getTime();
      if (actualTime > expirationTime) {
        store.dispatch("logout");
        router.replace("/");
      }
    }
    return Promise.reject(error);
  }
);

new Vue({
  vuetify,
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
