import axios from "axios";

// states
const state = () => ({});

// getters
const getters = {};

// actions
const actions = {
  dataChartCatechistJurisdictions() {
    return new Promise((resolve, reject) => {
      axios
        .get("/reports/catechists/jurisdictions")
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  dataChartCatechistTalentsStatus(_, data) {
    return new Promise((resolve, reject) => {
      axios
        .get("/reports/catechists/talent/status?jurisdiction=" + data)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  dataChartCatechistOld(_, data) {
    return new Promise((resolve, reject) => {
      axios
        .get("/reports/catechists/age?jurisdiction=" + data)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  dataChartCatechistTalent(_, data) {
    return new Promise((resolve, reject) => {
      axios
        .get("/reports/catechists/talent?jurisdiction=" + data)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  dataChartCatechistService(_, data) {
    return new Promise((resolve, reject) => {
      axios
        .get("/reports/catechists/service?jurisdiction=" + data)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  dataChartTrainings(_, data) {
    return new Promise((resolve, reject) => {
      axios
        .get("/reports/catechists/courses?jurisdiction=" + data)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  dataStatisticalBasic(_, data) {
    return new Promise((resolve, reject) => {
      axios
        .get("/reports/catechists/statistical/basic?jurisdiction=" + data)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
};

// mutations
const mutations = {};

export default {
  state,
  getters,
  actions,
  mutations,
};
