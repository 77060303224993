<template>
  <v-dialog
    v-model="dialog"
    :max-width="mobileWindow()"
    min-width="50%"
    :persistent="loading"
  >
    <v-card class="pb-4">
      <v-card flat color="secondary" :loading="loading">
        <v-card-title class="justify-center pb-5 mb-5 font-weight-bold">
          Descargar información
        </v-card-title>
      </v-card>
      <v-form
        ref="downloadForm"
        v-model="valid"
        :disabled="loading"
        lazy-validation
        autocomplete="new"
      >
        <v-card-title class="pt-2 justify-center">
          <v-row align="center" justify="center">
            <v-col cols="12">
              <v-text-field
                v-model="idNumber"
                :rules="[rules.required]"
                label="Cédula de identidad"
                maxlength="20"
                required
                hint="Ej. 98765422"
                class="pb-2 mb-2"
                persistent-hint
                outlined
                clearable
              ></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-text-field
                v-model="password"
                :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
                :rules="[rules.required]"
                :type="show ? 'text' : 'password'"
                label="Contraseña"
                @click:append="show = !show"
                hint="Ej. AAAAMMDD"
                persistent-hint
                outlined
                required
                clearable
              ></v-text-field>
            </v-col>
          </v-row>
        </v-card-title>
        <v-card-actions>
          <v-btn @click="closeDialog" color="secondary" :disabled="loading">
            Cancelar
          </v-btn>
          <v-spacer />
          <v-btn
            :disabled="!valid || loading"
            :loading="loading"
            color="primary"
            @click="changePassword"
          >
            Confirmar
          </v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
import { authRules } from "@/mixins/rules.js";
export default {
  mixins: [authRules],
  computed: {
    dialog: {
      get() {
        return this.$store.getters.isDownloading;
      },
      set(value) {
        this.$store.dispatch("changeDownloadData", value);
      },
    },
  },
  methods: {
    changePassword() {
      if (this.$refs.downloadForm.validate()) {
        this.loading = true;
        this.$store
          .dispatch("getCatechistReportAnonymous", {
            idNumber: this.idNumber,
            password: this.password,
          })
          .then(() => {
            this.closeDialog();
            this.reset();
          })
          .catch(() => {
            this.idNumber = "";
            this.password = "";
          })
          .finally(() => {
            this.loading = false;
          });
      }
    },
    closeDialog() {
      this.$store.dispatch("changeDownloadData", false);
    },
    reset() {
      this.$refs.downloadForm.reset();
    },
    resetValidation() {
      this.$refs.downloadForm.resetValidation();
    },
    mobileWindow() {
      if (this.$vuetify.breakpoint.mdAndDown) {
        return "80%";
      }
      return "45%";
    },
  },
  data: () => ({
    valid: false,
    password: "",
    idNumber: "",
    show: false,
    loading: false,
  }),
};
</script>
