<template>
  <div class="text-center">
    <v-dialog v-model="dialog" :max-width="mobileWindow()" class="ma-3 pa-3">
      <v-card color="success">
        
        <v-card-title class="justify-center white--text word-text">
          Los datos se han guardado correctamente
        </v-card-title>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="accent" @click="dialog = false"> Aceptar </v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
  
  <script>
export default {
  computed: {
    dialog: {
      get() {
        return this.$store.getters.isSuccessDialog;
      },
      set(value) {
        this.$store.dispatch("changeSuccessDialog", value);
      },
    },
  },
  methods: {
    mobileWindow() {
      if (this.$vuetify.breakpoint.mdAndDown) {
        return "70%";
      }
      return "50%";
    },
  },
};
</script>
  
  <style>
.word-text {
  word-break: normal;
  text-align: center;
}
</style>