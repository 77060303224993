import axios from "axios";
import { v4 as uuidv4 } from "uuid";

const state = () => ({
  uid: localStorage.getItem("uuid"),
  accessToken: localStorage.getItem("access_token"),
  refreshToken: localStorage.getItem("refresh_token"),
  expirationTime: localStorage.getItem("expires_in"),
  superuser: localStorage.getItem("dcatechist"),
});

// getters
const getters = {
  isLoggedIn(state) {
    return state.expirationTime &&
      state.refreshToken &&
      state.accessToken &&
      state.uid
      ? true
      : false;
  },
  isSudo(state) {
    return state.superuser;
  },
};

// actions
const actions = {
  adminLogin({ commit }, user) {
    return new Promise((resolve, reject) => {
      const dataToString =
        "grant_type=password&username=" +
        user.email +
        "&password=" +
        user.password;
      axios({
        url: "/oauth/token",
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          Authorization:
            "Basic b2F1dGgtY2ViLWNhdGVjaGlzbS1zZXJ2aWNlczpjZWItY2F0ZWNoaXNtLXNlcnZpY2Vz",
        },
        data: dataToString,
      })
        .then((resp) => {
          console.log(resp);
          axios.defaults.headers.common.Authorization =
            resp.data.token_type + " " + resp.data.access_token;
          localStorage.setItem("access_token", resp.data.access_token);
          localStorage.setItem("refresh_token", resp.data.refresh_token);
          localStorage.setItem("token_type", resp.data.token_type);
          localStorage.setItem(
            "expires_in",
            resp.data.expires_in * 100 + new Date().getTime()
          );
          localStorage.setItem("uuid", resp.data.uuid);
          localStorage.setItem("role", resp.data.role);
          localStorage.setItem("dcatechist", resp.data.sudo);
          commit("AUTH_SUCCESS", resp.data);
          resolve(resp);
        })
        .catch((err) => {
          commit("AUTH_ERROR");
          localStorage.removeItem("access_token");
          localStorage.removeItem("refresh_token");
          localStorage.removeItem("token_type");
          localStorage.removeItem("expires_in");
          localStorage.removeItem("user");
          localStorage.removeItem("role");
          localStorage.removeItem("dcatechist");
          reject(err);
        });
    });
  },
  adminSignUp({ commit }, data) {
    return new Promise((resolve, reject) => {
      const dataToString =
        "grant_type=password&username=" +
        data.email +
        "&password=" +
        data.password;
      var myuuid = uuidv4();
      data.uid = myuuid;
      axios
        .post("/admins/signup?register=" + data.code, data)
        .then(() => {
          axios({
            url: "/oauth/token",
            method: "POST",
            headers: {
              "Content-Type": "application/x-www-form-urlencoded",
              Authorization:
                "Basic b2F1dGgtY2ViLWNhdGVjaGlzbS1zZXJ2aWNlczpjZWItY2F0ZWNoaXNtLXNlcnZpY2Vz",
            },
            data: dataToString,
          })
            .then((resp) => {
              axios.defaults.headers.common.Authorization =
                resp.data.token_type + " " + resp.data.access_token;
              localStorage.setItem("access_token", resp.data.access_token);
              localStorage.setItem("refresh_token", resp.data.refresh_token);
              localStorage.setItem("token_type", resp.data.token_type);
              localStorage.setItem(
                "expires_in",
                resp.data.expires_in * 100 + new Date().getTime()
              );
              localStorage.setItem("uuid", resp.data.uuid);
              localStorage.setItem("role", resp.data.role);
              localStorage.setItem("dcatechist", resp.data.sudo);
              commit("AUTH_SUCCESS", resp.data);
              resolve(resp);
            })
            .catch((err) => {
              commit("AUTH_ERROR");
              localStorage.removeItem("access_token");
              localStorage.removeItem("refresh_token");
              localStorage.removeItem("token_type");
              localStorage.removeItem("expires_in");
              localStorage.removeItem("user");
              localStorage.removeItem("role");
              localStorage.removeItem("user");
              localStorage.removeItem("dcatechist");
              reject(err);
            });
        })
        .catch((error) => {
          reject(error.response);
        });
    });
  },
  adminChangePassword({ state }, data) {
    return new Promise((resolve, reject) => {
      data.uid = state.uid;
      axios
        .put("/admins/" + state.uid + "/password", data)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  logout({ commit }) {
    delete axios.defaults.headers.common["Authorization"];
    localStorage.removeItem("uuid");
    localStorage.removeItem("access_token");
    localStorage.removeItem("refresh_token");
    localStorage.removeItem("token_type");
    localStorage.removeItem("expires_in");
    localStorage.removeItem("role");
    localStorage.removeItem("dcatechist");
    commit("LOG_OUT");
  },
  getUserInfo({ state, commit }) {
    return new Promise((resolve, reject) => {
      axios
        .get("/admins/details?userUid=" + state.uid)
        .then((response) => {
          commit("UPDATE_SUDO", response.data.emailVerified);
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  saveUserData({ state }, data) {
    return new Promise((resolve, reject) => {
      data.uid = state.uid;
      console.log(data);
      axios
        .put("/admins/update", data)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          console.log(error.response);
          reject(error);
        });
    });
  },
  getListAdmins({ state }) {
    return new Promise((resolve, reject) => {
      axios
        .get("/admins?userUid=" + state.uid)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  addNewAdmin({ state }, data) {
    return new Promise((resolve, reject) => {
      var myuuid = uuidv4();
      data.uid = myuuid;
      console.log(data);
      axios
        .post("/admins?userUid=" + state.uid, data)
        .then(() => {
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  updateAdmin({ state }, data) {
    return new Promise((resolve, reject) => {
      axios
        .put("/admins?userUid=" + state.uid, data)
        .then(() => {
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  deleteAdmin({ state }, data) {
    return new Promise((resolve, reject) => {
      axios
        .delete("/admins?userUid=" + state.uid, { data: data })
        .then(() => {
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  changeStatus({ state }, data) {
    return new Promise((resolve, reject) => {
      data.status = !data.status;
      axios
        .put("/admins/status/admins?userUid=" + state.uid, data)
        .then(() => {
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
};

// mutations
const mutations = {
  AUTH_ERROR(state) {
    state.uid = null;
    state.accessToken = null;
    state.refreshToken = null;
    state.expirationTime = null;
    state.superuser = null;
  },
  AUTH_SUCCESS(state, payload) {
    state.uid = payload.uuid;
    state.accessToken = payload.access_token;
    state.refreshToken = payload.refresh_token;
    state.expirationTime = payload.expires_in + new Date().getTime();
    state.superuser = payload.sudo;
  },
  LOG_OUT(state) {
    state.uid = null;
    state.accessToken = null;
    state.refreshToken = null;
    state.expirationTime = null;
    state.superuser = null;
  },
  UPDATE_SUDO(state, payload) {
    state.superuser = payload;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
