<template>
  <div class="text-center">
    <v-dialog v-model="dialog" :max-width="mobileWindow()">
      <v-card>
        <v-card-title class="justify-center display-1 font-weight-black">
          <v-img max-width="150" src="@/assets/error.png"></v-img>
        </v-card-title>

        <v-card-title
          class="justify-center display-1 font-weight-black word-text"
        >
          La información del archivo no es válida
        </v-card-title>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" @click="dialog = false"> Continuar </v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  computed: {
    dialog: {
      get() {
        return this.$store.getters.isErrorFileDialog;
      },
      set(value) {
        this.$store.dispatch("fileUnsupportedDialog", value);
      },
    },
  },
  methods: {
    mobileWindow() {
      if (this.$vuetify.breakpoint.mdAndDown) {
        return "90%";
      }
      return "50%";
    },
  },
};
</script>

<style>
.word-text {
  word-break: normal;
  text-align: center;
}
</style>