<template>
  <v-dialog
    v-model="show"
    :max-width="mobileWindow()"
    min-width="90%"
    :persistent="loading"
  >
    <div>
      <v-tabs
        v-model="tab"
        show-arrows
        background-color="primary"
        icons-and-text
        dark
        grow
      >
        <v-tabs-slider color="secondary"></v-tabs-slider>
        <v-tab v-for="(i, key) in tabs" :key="key" :disabled="loading">
          <v-icon large>{{ i.icon }}</v-icon>
          <div class="caption py-1">{{ i.name }}</div>
        </v-tab>
        <v-tab-item>
          <v-card class="px-4">
            <v-form
              ref="loginForm"
              v-model="validLogin"
              :disabled="loading"
              lazy-validation
            >
              <v-card-text class="pa-5 pt-7 text-center">
                <v-row>
                  <v-col cols="12">
                    <v-text-field
                      v-model="loginEmail"
                      :rules="loginEmailRules"
                      label="Correo electronico"
                      @keyup.enter="login"
                      required
                      hide-details
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12">
                    <v-text-field
                      v-model="loginPassword"
                      :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                      :rules="[rules.required, rules.min]"
                      :type="show1 ? 'text' : 'password'"
                      label="Contraseña"
                      @keyup.enter="login"
                      @click:append="show1 = !show1"
                      hide-details
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-card-text>
              <v-card-actions>
                <v-btn
                  @click="show = false"
                  color="secondary"
                  :disabled="loading"
                  :small="$vuetify.breakpoint.mdAndDown"
                >
                  Cancelar
                </v-btn>
                <v-spacer></v-spacer>
                <v-btn
                  :disabled="!validLogin || loading"
                  :loading="loading"
                  color="primary"
                  @click="login"
                  :small="$vuetify.breakpoint.mdAndDown"
                >
                  Iniciar Sesion
                </v-btn>
              </v-card-actions>
            </v-form>
          </v-card>
        </v-tab-item>
        <v-tab-item>
          <v-card class="px-4">
            <v-card-text class="text-center">
              <v-form
                ref="registerForm"
                v-model="validRegister"
                :disabled="loading"
                lazy-validation
              >
                <v-row>
                  <v-col cols="12" sm="6" md="6">
                    <v-text-field
                      v-model="firstName"
                      :rules="[rules.required]"
                      label="Nombres"
                      maxlength="20"
                      required
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="6">
                    <v-text-field
                      v-model="lastName"
                      :rules="[rules.required]"
                      label="Apellidos"
                      maxlength="20"
                      required
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="6">
                    <v-text-field
                      v-model="email"
                      :rules="emailRules"
                      label="Correo electronico"
                      required
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="6">
                    <v-text-field
                      v-model.number="cellphone"
                      label="Numero de celular"
                      type="number"
                      :rules="cellphoneRules"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="6">
                    <v-text-field
                      v-model="password"
                      :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                      :rules="[rules.required, rules.min]"
                      :type="show1 ? 'text' : 'password'"
                      label="Contraseña"
                      hint="Al menos 8 caracteres"
                      counter
                      @click:append="show1 = !show1"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="6">
                    <v-text-field
                      block
                      v-model="verify"
                      :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                      :rules="[rules.required, passwordMatch]"
                      :type="show1 ? 'text' : 'password'"
                      label="Confirmar contraseña"
                      counter
                      @click:append="show1 = !show1"
                      required
                    ></v-text-field>
                  </v-col>

                  <v-col cols="12">
                    <v-text-field
                      v-model="registerCode"
                      label="Codigo de registro"
                      :append-icon="show2 ? 'mdi-eye' : 'mdi-eye-off'"
                      :rules="[rules.required]"
                      @click:append="show2 = !show2"
                      required
                    ></v-text-field>
                  </v-col>

                  <v-col cols="12" sm="6" md="6" xl="6" lg="6">
                    <v-btn
                      @click="show = false"
                      color="secondary"
                      :disabled="loading"
                    >
                      Cancelar
                    </v-btn>
                  </v-col>
                  <v-col cols="12" sm="6" md="6" xl="6" lg="6">
                    <v-btn
                      :disabled="!validRegister || loading"
                      :loading="loading"
                      @click="register"
                      color="primary"
                    >
                      Registrarse
                    </v-btn>
                  </v-col>
                </v-row>
              </v-form>
            </v-card-text>
          </v-card>
        </v-tab-item>
      </v-tabs>
    </div>
  </v-dialog>
</template>

<script>
import { authRules } from "@/mixins/rules.js";
export default {
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    tab: 0,
    tabs: [
      { name: "Iniciar Sesion", icon: "mdi-account" },
      { name: "Registrarse", icon: "mdi-account-outline" },
    ],
    validLogin: true,
    validRegister: true,
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    verify: "",
    loginPassword: "",
    loginEmail: "",
    registerCode: "",
    cellphone: "",
    show1: false,
    show2: false,
    loading: false,
  }),
  mixins: [authRules],
  computed: {
    passwordMatch() {
      return () =>
        this.password === this.verify || "Las contraseñas deben ser iguales";
    },
    show: {
      get() {
        return this.visible;
      },
      set(value) {
        if (!value) {
          this.$emit("close");
        }
      },
    },
  },
  methods: {
    login() {
      if (this.$refs.loginForm.validate()) {
        this.loading = true;
        this.$store
          .dispatch("adminLogin", {
            email: this.loginEmail,
            password: this.loginPassword,
          })
          .then(() => {
            this.show = false;
            this.$refs.loginForm.reset();
          })

          .finally(() => {
            this.loading = false;
            this.firstName = "";
            this.lastName = "";
            this.email = "";
            this.password = "";
            this.verify = "";
            this.loginPassword = "";
            this.loginEmail = "";
          });
      }
    },
    register() {
      if (this.$refs.registerForm.validate()) {
        this.loading = true;
        this.$store
          .dispatch("adminSignUp", {
            email: this.email,
            password: this.password,
            name: this.firstName,
            surname: this.lastName,
            cellphone: this.cellphone,
            code: this.registerCode,
          })
          .then(() => {
            this.reset();
            this.show = false;
          })
          .catch((error) => {
            if (error.data.status == 406) this.registerCode = "";
            if (error.data.status == 409) this.email = "";
          })

          .finally(() => {
            this.loading = false;
          });
      }
    },
    reset() {
      this.$refs.registerForm.reset();
    },
    resetValidation() {
      this.$refs.registerForm.resetValidation();
    },
    mobileWindow() {
      if (this.$vuetify.breakpoint.mdAndDown) {
        return "90%";
      }
      return "50%";
    },
  },
};
</script>

<style></style>
