<template>
  <v-menu bottom min-width="200px" rounded offset-y>
    <template v-slot:activator="{ on }">
      <v-btn v-if="user.picture" icon x-large v-on="on" class="mr-1 pr-1">
        <v-avatar color="primary" size="62">
          <img
            height="62px"
            width="62px"
            :src="user.picture"
            alt="Profile Image"
          />
        </v-avatar>
      </v-btn>
      <v-btn v-else icon x-large v-on="on">
        <v-avatar color="primary" size="48">
          <span class="white--text text-h5">{{ user.initials }}</span>
        </v-avatar>
      </v-btn>
    </template>
    <v-card>
      <v-list-item-content class="justify-center">
        <div class="mx-auto text-center">
          <h3>{{ user.name }}</h3>
          <p class="text-caption mt-1">
            {{ user.email }}
          </p>
          <v-divider class=""></v-divider>
          <v-btn block text to="/account"> Cuenta </v-btn>
          <v-divider class=""></v-divider>
          <v-btn block text to="/register"> Registrar catequistas </v-btn>
          <v-divider class=""></v-divider>
          <v-btn block text to="/dashboard"> Graficos estadisticos </v-btn>
          <v-divider class=""></v-divider>
          <v-btn @click="logout" depressed block text> Cerrar Sesion </v-btn>
          <v-divider class=""></v-divider>
        </div>
      </v-list-item-content>
    </v-card>
  </v-menu>
</template>

<script>
export default {
  data: () => ({
    user: {},
  }),
  async created() {
    await this.$store.dispatch("getUserInfo").then((response) => {
      this.user = JSON.parse(JSON.stringify(response));
      this.user.initials =
        this.user.name.charAt(0) + this.user.surname.charAt(0);
    });
  },

  methods: {
    logout() {
      this.$store.dispatch("logout");
      this.$router.push({ name: "Home" }).catch((error) => {
        console.warn(error);
      });
    },
  },
};
</script>

<style></style>
