<template>
  <v-footer color="primary" padless>
    <v-row justify="center" align="center" no-gutters>
      <v-col cols="12" class="pt-2 text-center">
        <div>
          <v-btn
            v-for="(link, i) in links"
            :key="i"
            color="white"
            text
            rounded
            class="my-2 text-caption"
            :to="link.to"
          >
            {{ link.link }}
          </v-btn>
        </div>
        <div v-if="$store.getters.isLoggedIn">
          <v-btn
            v-for="(linksAu, i) in linksAuth"
            :key="linksAu.to + i"
            color="white"
            text
            rounded
            class="my-2 text-caption"
            :to="linksAu.to"
          >
            {{ linksAu.link }}
          </v-btn>
        </div>
        <v-btn
          v-if="$store.getters.isLoggedIn && $store.getters.isSudo"
          color="white"
          text
          to="/admins"
          rounded
          class="my-2 text-caption"
        >
          Administracion
        </v-btn>
      </v-col>
      <v-col
        cols="12"
        md="8"
        sm="8"
        class="text-caption pt-5 text-center white--text"
      >
        <p class="font-weight-light">
          “la formación de los catequistas ayuda a desarrollar las habilidades
          necesarias para la comunicación de la fe y para acompañar el
          crecimiento de los hermanos.” (DpC 132)
        </p>
      </v-col>
      <v-col cols="12"></v-col>
      <v-col class="primary py-1 text-center white--text" cols="12">
        Copyright &copy; {{ new Date().getFullYear() }}
      </v-col>
    </v-row>
  </v-footer>
</template>
<script>
export default {
  data: () => ({
    links: [
      { link: "Inicio", to: "/" },
      { link: "Verificar", to: "/scan" },
      { link: "Sobre nosotros", to: "/about" },
    ],
    linksAuth: [
      { link: "Registrar", to: "/register" },
      { link: "Cuenta", to: "/account" },
      { link: "Tablero", to: "/dashboard" },
    ],
  }),
};
</script>
