import Vue from "vue";
import Vuex from "vuex";
import user from "./modules/user";
import dialog from "./modules/dialog";
import charts from "./modules/charts";
import catechists from "./modules/catechists";

const debug = process.env.NODE_ENV !== "production";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    user,
    dialog,
    charts,
    catechists,
  },

  strict: debug,
  // plugins: debug ? [createLogger()] : [],
});
