//  npm install echarts vue-echarts
// For V2 //  npm i -D @vue/composition-api
// https://echarts.apache.org/examples/en/index.html
import Vue from "vue";
import VChart from "vue-echarts"; // refers to components/ECharts.vue in webpack
import { use } from "echarts/core";
import { CanvasRenderer } from "echarts/renderers";

import { LineChart, PieChart, RadarChart, BarChart } from "echarts/charts";

import {
  TitleComponent,
  TooltipComponent,
  LegendComponent,
  GridComponent,
} from "echarts/components";
import "echarts/lib/component/legend";

use([
  CanvasRenderer,
  PieChart,
  TitleComponent,
  TooltipComponent,
  LegendComponent,
  BarChart,
  GridComponent,
  LineChart,
  RadarChart,
]);

// register globally (or you can do it locally)
Vue.component("v-chart", VChart);
