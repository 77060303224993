import Vue from "vue";
import Vuetify from "vuetify/lib/framework";

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    themes: {
      light: {
        primary: "#2B4C8D",
        secondary: "#9FACD6",
        tertiary: "#F4F6F9",
        quaternary: "#120907",
        accent: "#145c83",
        error: "#b71c1c",
      },
      dark: {
        primary: "#676485",
        secondary: "#25272d",
        tertiary: "#000000",
        quaternary: "#120907",
        accent: "#145c83",
        error: "#b71c1c",
      },
    },
  },
});
