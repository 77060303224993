<template>
  <v-dialog
    v-model="dialog"
    :max-width="mobileWindow()"
    min-width="50%"
    :persistent="loading"
  >
    <v-card class="px-4 pb-4">
      <v-card-title class="pt-2 justify-center">
        <v-form
          ref="passwordForm"
          v-model="valid"
          :disabled="loading"
          lazy-validation
        >
          <v-row no-gutters align="center" justify="center">
            <v-col cols="12">
              <v-card-title class="justify-center font-weight-bold">
                Cambiar Contraseña
              </v-card-title>
            </v-col>
            <v-col cols="12">
              <v-text-field
                v-model="oldPassword"
                :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                :rules="[rules.required, rules.min]"
                :type="show1 ? 'text' : 'password'"
                label="Contraseña Actual"
                @click:append="show1 = !show1"
                outlined
              ></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-text-field
                v-model="newPassword"
                :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                :rules="[rules.required, rules.min]"
                :type="show1 ? 'text' : 'password'"
                label="Contraseña Nueva"
                @click:append="show1 = !show1"
                outlined
              ></v-text-field>
            </v-col>

            <v-col cols="12" sm="6" md="6" xl="6" lg="6">
              <v-btn @click="closeDialog" color="secondary" :disabled="loading">
                Cancelar
              </v-btn>
            </v-col>
            <v-col
              cols="12"
              sm="6"
              md="6"
              xl="6"
              lg="6"
              class="d-flex flex-row-reverse"
            >
              <v-btn
                :disabled="!valid || loading"
                :loading="loading"
                color="primary"
                @click="changePassword"
              >
                Confirmar
              </v-btn>
            </v-col>
          </v-row>
        </v-form>
      </v-card-title>
    </v-card>
  </v-dialog>
</template>

<script>
import { authRules } from "@/mixins/rules.js";
export default {
  mixins: [authRules],
  computed: {
    passwordMatch() {
      return () => this.password === this.verify || "Password must match";
    },
    dialog: {
      get() {
        return this.$store.getters.isUpdatingPasswordD;
      },
      set(value) {
        this.$store.dispatch("changeUpdatingPassStatus", value);
      },
    },
  },
  methods: {
    changePassword() {
      if (this.$refs.passwordForm.validate()) {
        this.loading = true;
        this.$store
          .dispatch("adminChangePassword", {
            oldPassword: this.oldPassword,
            newPassword: this.newPassword,
          })
          .then(() => {
            this.$store.dispatch("changeUpdatingPassStatus", false);
          })
          .catch(() => {
            this.oldPassword = "";
            this.newPassword = "";
          })
          .finally(() => {
            this.loading = false;
          });
      }
    },
    closeDialog() {
      this.$store.dispatch("changeUpdatingPassStatus", false);
    },
    reset() {
      this.$refs.passwordForm.reset();
    },
    resetValidation() {
      this.$refs.passwordForm.resetValidation();
    },
    mobileWindow() {
      if ( this.$vuetify.breakpoint.mdAndDown) {
        return "90%";
      }
      return "50%";
    },
  },
  data: () => ({
    valid: false,
    oldPassword: "",
    newPassword: "",
    show1: false,
    loading: false,
  }),
};
</script>

<style>
</style>