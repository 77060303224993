export const authRules = {
  data: () => ({
    loginEmailRules: [
      (v) => !!v || "Requerido",
      (v) => /.+@.+\..+/.test(v) || "Debe ser un correo valido",
    ],
    emailRules: [
      (v) => !!v || "Requerido",
      (v) => /.+@.+\..+/.test(v) || "Debe ser un correo valido",
    ],
    cellphoneRules: [
      (v) => !!v || "Requerido",
      (v) => {
//        if (!v.trim()) return true;
        if (!isNaN(parseFloat(v)) && v >= 60000000 && v <= 79999999) return true;
        return "El numero debe ser entre 60000000 y 79999999";
      },
    ],
    rules: {
      required: (value) => !!value || "Requerido.",
      min: (v) => (v && v.length >= 8) || "Min 8 caracteres",
    },
  }),
};
