import Vue from "vue";
import VueRouter from "vue-router";
import store from "@/store";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: () => import("@/views/HomeView.vue"),
  },
  {
    path: "/account",
    name: "Account",
    component: () => import("@/views/AccountView.vue"),
    beforeEnter: (to, from, next) => {
      if (store.getters.isLoggedIn) next();
      else next("/");
    },
  },
  {
    path: "/register",
    name: "Register",
    component: () => import("@/views/RegisterView.vue"),
    beforeEnter: (to, from, next) => {
      if (store.getters.isLoggedIn) next();
      else next("/");
    },
  },
  {
    path: "/search",
    name: "Search",
    component: () => import("@/views/SearchView.vue"),
    // beforeEnter: (to, from, next) => {
    //   if (store.getters.isLoggedIn) next();
    //   else next("/");
    // },
  },
  {
    path: "/catechist/:idCatechist",
    name: "Catechist",
    component: () => import("@/views/CatechistView.vue"),
    beforeEnter: (to, from, next) => {
      if (store.getters.isLoggedIn) next();
      else next("/");
    },
    props: true,
  },
  {
    path: "/about",
    name: "about",
    component: () => import("@/views/AboutView.vue"),
  },
  {
    path: "/scan",
    name: "Scan",
    component: () => import("@/views/ScanCodeView.vue"),
  },
  {
    path: "/catechist/:idCatechist/valid",
    name: "CatechistValidation",
    component: () => import("@/views/CatechistValidView.vue"),
    beforeEnter: (to, from, next) => {
      if (from.name) next();
      else next("/");
    },
    props: true,
  },
  {
    path: "/dashboard",
    name: "Dashboard",
    component: () => import("@/views/DashboardView.vue"),
    beforeEnter: (to, from, next) => {
      if (store.getters.isLoggedIn) next();
      else next("/");
    },
  },
  {
    path: "/admins",
    name: "ManageAdmins",
    component: () => import("@/views/AdminManagerView.vue"),
    beforeEnter: (to, from, next) => {
      if (store.getters.isLoggedIn && store.getters.isSudo) next();
      else next("/");
    },
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
});

export default router;
